<template>
  <va-badge :text="getIdentityText()" :color="getIdentityColor()" />
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t , te } = useI18n();

const props = defineProps({
  type: {
    type: String,
    required: true
  }
});

function getIdentityText() {
  const roleStrKey = 'identities.types.' + props.type;

  return te(roleStrKey) ? t(roleStrKey) : props.type;
}

function getIdentityColor() {
  switch (props.type) {
    case "User":
      return "#4BE3E3";

    case "Invite":
      return "#E34B97";

    case "Organization":
      return "#E3E34B";

    default:
      return "#974BE3"
  }
}
</script>

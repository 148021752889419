<template>
  <va-modal
    v-model="state.isOpen"
    hide-default-actions
    :title="$t('addMemberModel.title')"
  >
    <slot>
      <div class="bodyContainer">
        <div class="email-container">
          <va-input
            v-model="state.email"
            label="Email"
            placeholder="Enter email"
            type="email"
            :rules="[emailRule]"
            :error="state.emailError"
          />
        </div>
        <h4>{{ $t("addMemberModel.selectRoles") }}</h4>
        <div v-for="role in state.roleOptions" :key="role.value" class="checkbox-container">
          <va-checkbox
            v-model="role.selected"
            :label="`${role.label} (${role.value})`"
            :disabled="state.isUpdating"
          />
        </div>
      </div>
    </slot>
    <template #footer>
      <va-button flat outline class="mr-1" @click="emit('cancel')" :disabled="state.isUpdating">
        {{ $t("buttons.cancel") }}
      </va-button>
      <va-button class="ml-1" @click="applyChange" :disabled="state.isUpdating">
        {{ $t("buttons.addMember") }}
      </va-button>
    </template>
  </va-modal>
</template>

<script setup>
import { computed, reactive, watch, onMounted } from 'vue';
import { callFetchRoles } from '@/api/identity';



const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  }
});

const state = reactive({
  isOpen: computed(() => props.isOpen),
  email: '',
  emailError: false,
  roleOptions: [],
  isUpdating: false
});

const emailRule = (value) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(value) || 'Invalid email address';
};

const fetchRoles = async () => {
  try {
    const resp = await callFetchRoles();
    if (resp?.data) {
      state.roleOptions = resp.data.map(role => ({
        label: role.displayName,
        value: role.name,
        description: role.description,
        selected: false
      }));
    }
  } catch (err) {
    console.error('Error fetching roles:', err);
  }
};

const resetModalState = () => {
  state.isUpdating = false; // Reset loading state when the modal is opened
  state.email = ''; // Reset email input
  state.emailError = false;
  state.roleOptions.forEach(role => role.selected = false); // Reset role selections
};

onMounted(async () => {
  await fetchRoles();
});

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen) {
      resetModalState();
    }
  }
);

const emit = defineEmits(['cancel', 'addMember']);

const applyChange = () => {
  if (!state.email || !emailRule(state.email)) {
    state.emailError = true;
    return;
  }
  if (state.roleOptions.every(role => !role.selected)) {
    alert('Please select at least one role');
    return;
  }
  state.isUpdating = true;
  emit('addMember', { email: state.email, roles: state.roleOptions.filter(role => role.selected).map(role => role.value) });
};
</script>

<style lang="scss" scoped>
.bodyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 520px;
  padding-left: 3rem;
  line-height: 2rem;
}
.email-container {
  margin-bottom: 1rem;
  width: 100%;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* Optional: Adds space between checkboxes */
}
.checkbox__square {
  margin-right: 0.5rem; /* Optional: Adds space between checkbox and label */
  align-items: center;
  background-color: var(--va-checkbox-square-background-color, var(--va-background-color));
  border: var(--va-checkbox-square-border, var(--va-control-border));
  border-radius: var(--va-checkbox-square-border-radius, var(--va-square-border-radius));
  display: flex;
  height: var(--va-checkbox-square-height);
  justify-content: center;
  min-width: var(--va-checkbox-square-min-width);
  position: var(--va-checkbox-square-position);
  width: var(--va-checkbox-square-width);
}
:root {
  --va-checkbox-display: block;
  --va-checkbox-max-width: fit-content;
  --va-checkbox-input-align-items: center;
  --va-checkbox-input-display: flex;
  --va-checkbox-input-padding: 0 0.3rem;
  --va-checkbox-input-cursor: pointer;
  --va-checkbox-disabled-cursor: default;
  --va-checkbox-readonly-cursor: initial;
  --va-checkbox-label-display: inline-block;
  --va-checkbox-label-position: relative;
  --va-checkbox-icon-pointer-events: none;
  --va-checkbox-icon-position: absolute;
  --va-checkbox-icon-color: transparent;
  --va-checkbox-selected-icon-color: #fff;
  --va-checkbox-indeterminate-icon-color: #fff;
  --va-checkbox-square-width: 1.35rem;
  --va-checkbox-square-min-width: 1.35rem;
  --va-checkbox-square-height: 1.35rem;
  --va-checkbox-square-position: relative;
  --va-checkbox-square-background-color: #fff;
  --va-checkbox-square-border: solid 0.125rem #acb5be;
  --va-checkbox-square-border-radius: 0.25rem;
}
</style>

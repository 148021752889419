<template>
  <base-tile
    :title="$t('organizations.info')"
    i18nPrefix="organizations"
    :rows="state.rows"
  >
    <!-- ID -->
    <template #[state.readOrganizationIdSlot]>
      <td v-if="state.id" class="p-2">
        <span>{{ state.id }} </span>
        <button-clipboard-copy
          :field="$t('organizations.id')"
          :value="state.id"
        />
      </td>
    </template>
  </base-tile>
</template>

<script setup>
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import BaseTile from "@/components/BaseTile.vue";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import { printDate } from "@/utils";

const { t } = useI18n();
const store = useStore();

const state = reactive({
  id: store.state.identity.organization.id,
  name: store.state.identity.organization.name,
  createdAt: store.state.identity.organization.createdAt,
  rows: computed(() => {
    const organization = store.state.identity.organization;
    return [
      {
        label: t("organizations.id"),
        value: organization.id
      },
      {
        label: t("organizations.name"),
        value: organization.name
      },
      {
        label: t("organizations.createdAt"),
        value: printDate(organization.createdAt)
      }
    ];
  }),
  readOrganizationIdSlot: `${t("organizations.id")}-value`,
});
</script>

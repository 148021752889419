<template>
  <va-badge :text="getRoleText()" :color="getRoleColor()" />
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t , te } = useI18n();

const props = defineProps({
  role: {
    type: String,
    required: true
  }
});

function getRoleText() {
  const roleStrKey = 'members.roles.' + props.role;

  return te(roleStrKey) ? t(roleStrKey) : props.role;
}

function getRoleColor() {
  switch (props.role) {
    case "OrganizationOwner":
      return "#e34a4a";

    default:
      return "#E3974B";
  }
}
</script>

<template>
  <loading-spinner v-if="state.loading" />
  <div v-else>
    <the-header-card @refresh="loadOrganizationInfo" />

    <div class="flex flex-grow-1 xl16 mb-3">
      <organization-information-tile :editMode="false" />
    </div>

    <va-button-toggle
      outline
      class="mb-3"
      v-model="state.tableType"
      :options="SWITCH_OPTIONS"
    />
    <organization-users-table v-if="state.tableType === 'members-table'" />
    <entitlements-table
      v-if="state.tableType === 'entitlements-table'"
      :params="{ ownerId: route.params.id }"
    />
  </div>
</template>

<script setup>
import { computed, reactive, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TheHeaderCard from "@/components/TheHeaderCard.vue";
import EntitlementsTable from "@/components/entitlements/EntitlementsTable.vue";
import OrganizationUsersTable from "@/components/users/OrganizationUsersTable.vue";
import OrganizationInformationTile from "@/components/organizations/OrganizationInformationTile.vue";

const { t } = useI18n();
const route = useRoute();
const store = useStore();

const SWITCH_OPTIONS = [
  { label: t("members.title"), value: "members-table" },
  { label: t("entitlements.title"), value: "entitlements-table" }
];

const state = reactive({
  loading: false,
  organization: computed(() => store.state.identity.organization),
  tableType: "members-table"
});

const loadOrganizationInfo = async () => {
  state.loading = true;
  const accountId = route.params.id;
  await store.dispatch("identity/fetchOrganization", accountId);
  state.loading = false;
};

onMounted(() => loadOrganizationInfo());

// Add a watcher to log changes to tableType
watch(() => state.tableType, (newVal, oldVal) => {
  console.log(`tableType changed from ${oldVal} to ${newVal}`);
});
</script>

<style scoped>
.flex-grow-1 {
  flex-grow: 1;
}
</style>

<template>
  <va-card>
    <va-card-title>
      <slot name="title">
        {{ $t("members.title") }}
      </slot>
      <va-button flat class="upgrade-button" @click="openEventsLogs()">
        {{ $t("buttons.addaMember") }}
      </va-button>
    </va-card-title>

    <!-- Table -->
    <va-card-content>
      <va-data-table
        :no-data-html="$t('members.noItems')"
        :items="state.members"
      >
        <template #header(user)>
          {{ $t("members.rolesTitle") }}
        </template>

        <!-- Cells -->
        <template #cell(identity)="{ source: identity }">
          <td>
            <router-link :to="{ path: identityLink(identity) }">
              <span class="badges">
                {{ identity.name }}
                <identity-type-badge
                  v-if="identity.type !== 'User'"
                  :type="identity.type"
                />
              </span>
            </router-link>
          </td>
        </template>
        <template #cell(user)="{ source: user }">
          <td>
            <span class="cell">
              <span class="badges" v-if="user.roles.length > 0">
                <organization-role-badge
                  v-for="role in user.roles"
                  :role="role"
                  :key="role"
                />
              </span>
              <span v-else>
                {{ $t("members.noRole") }}
              </span>
              <va-button
                class="ml-2"
                flat
                size="small"
                icon="edit"
                @click="openEditOwnerModal(user.roles, user.id)"
              />
              <va-button
                color="#DC2626"
                flat
                size="small"
                icon="delete"
                @click="openDangerModal(user.id)"
              />
            </span>
          </td>
        </template>
        <template #cell(joinedAt)="{ source: joinedAt }">
          <td>
            {{ printDate(joinedAt) }}
          </td>
        </template>
      </va-data-table>
    </va-card-content>
    <va-card-content>
      <va-pagination
        class="pagination"
        input
        color="#000000"
        :hide-on-single-page="true"
        v-model="state.currentPage"
        :pages="state.totalPages"
        :page-size="state.perPage"
      />
    </va-card-content>
  </va-card>
  <user-role-modal
    :isOpen="state.editUserRoleModalIsOpen"
    :existingRoleIds="state.existingRoleIds"
    @cancel="state.editUserRoleModalIsOpen = false"
    @update="handleRolesUpdate"
  />
  <danger-confirm-modal
    :is-open="state.showDangerModal"
    :title="$t('members.deletion')"
    :message="$t('members.messageModal')"
    :button-validate-message="$t('members.deletion')"
    @cancel="cancelDelete"
    @validate="deleteOrgUser"
  />
  <add-member-modal
    :isOpen="state.showAddMemberModalIsOpen"
    @cancel="state.showAddMemberModalIsOpen = false"
    @addMember="addMember"
  />
</template>

<style scoped>
.cell {
  display: flex;
}

.badges {
  display: flex;
  align-items: center;
  gap: 5px;
}
.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  flex: 1; /* Ensures title takes up all available space */
}

.upgrade-button {
  margin-left: auto; /* Pushes the button to the right */
}
</style>

<script setup>
import { reactive, watch, onMounted, inject } from "vue";
import { useRoute } from "vue-router";
import {
  callFetchOrganizationMembers,
  callAddUserRole,
  callDeleteUserRole,
  callDeleteUserFromOrganization,
  callAddMember
} from "@/api/identity";
import { printDate } from "@/utils";
import { useI18n } from "vue-i18n";
import { identityLink } from "@/utils/identity";
import IdentityTypeBadge from "../IdentityTypeBadge.vue";
import OrganizationRoleBadge from "../OrganizationRoleBadge.vue";
import UserRoleModal from "@/components/modal/UserRoleModal.vue";
import DangerConfirmModal from "@/components/modal/DangerConfirmModal.vue";
import AddMemberModal from "@/components/modal/AddMemberModal.vue";

const { t } = useI18n();
const toasts = inject("toasts");
const route = useRoute();
const openEventsLogs = () => {
  state.showAddMemberModalIsOpen = true;
};

const state = reactive({
  members: [],
  lastLoadedPage: undefined,
  loading: false,
  existingRoleIds: [],
  currentPage: 1,
  totalItems: 0,
  perPage: 10,
  totalPages: 0,
  showDangerModal: false,
  deletableUserId: null,
  showAddMemberModalIsOpen: false
});
const openDangerModal = userId => {
  state.deletableUserId = userId;
  state.showDangerModal = true;
};
const deleteOrgUser = async () => {
  try {
    const orgId = route.params.id;
    const userId = state.deletableUserId;
    await callDeleteUserFromOrganization(orgId, userId);
    state.members = state.members.filter(member => member.user.id !== userId);
    state.showDangerModal = false;
    toasts.info(t("members.deleteSuccessMessage"));
  } catch (error) {
    console.error("Error deleting user:", error);
    toasts.error("Error deleting user.");
  }
};
const addMember = async ({ email, roles }) => {
  try {
    const orgId = route.params.id;
    const memberData = {
      email,
      senderName: "Your Name",
      locale: "en-US",
      roles
    };
    await callAddMember(orgId, memberData);
    state.showAddMemberModalIsOpen = false;
    await loadMembers(state.currentPage);
    toasts.info(t("members.addMember.addMemberSuccessMesage"));
  } catch (error) {
    console.error("Error adding member:", error);
    toasts.error(t("members.addMember.addMemberErrorMesage"));
  }
};
const openEditOwnerModal = (roles, userId) => {
  state.selectedUserId = userId;
  state.existingRoleIds = roles.map(role => role);
  state.editUserRoleModalIsOpen = true;
};

const cancelDelete = () => {
  state.deleteId = undefined;
  state.deleteUserModalIsOpen = false;
};
const handleRolesUpdate = async updatedRoles => {
  state.isUpdating = true;
  const orgId = route.params.id;
  const userId = state.selectedUserId;

  // Logging the user ID and updated roles for debugging purposes
  console.log(`User Id : ${userId}`);
  console.log(updatedRoles);

  try {
    // Iterate through updated roles and make the appropriate API calls
    for (const role of updatedRoles) {
      if (role.selected) {
        // If the role is selected, call the add user role API
        await callAddUserRole(orgId, userId, role.value);
      } else {
        // If the role is not selected, call the delete user role API
        await callDeleteUserRole(orgId, userId, role.value);
      }
    }
    state.isUpdating = false;

    state.editUserRoleModalIsOpen = false;
    // Notify success
    toasts.info(t("members.roleSuccessMesage"));
    await loadMembers(state.currentPage);
  } catch (error) {
    // Notify error
    state.editUserRoleModalIsOpen = false;
    state.isUpdating = false;
    console.error("Error updating roles:", error);
    toasts.error("Error updating roles.");
  }
};
/**
 * TO DO: mutualize with the one of OrganizationPage
 * Create the parameters object for the request and call it.
 * Then calculate the page properties's values.
 * @param {number} page - positive integer
 */
async function loadMembers(page = 1) {
  if (state.loading === true || page === state.lastLoadedPage) {
    return;
  }

  state.lastLoadedPage = page;
  state.loading = true;

  const orgId = route.params.id;
  const params = {
    count: state.perPage,
    page
  };

  try {
    const resp = await callFetchOrganizationMembers(orgId, { params });

    if (resp?.data) {
      state.members = resp.data.items.map(item => {
        return {
          identity: {
            id: item?.id,
            type: item?.type,
            name: item?.name
          },
          user: {
            roles: item?.roles ?? [],
            id: item?.id
          },
          joinedAt: item?.joinedAt
        };
      });

      state.totalItems = resp.data.total;
      state.currentPage = page;
      state.totalPages =
        Math.floor(resp.data.total / state.perPage) +
        (resp.data.total % state.perPage === 0 ? 0 : 1);
    }
  } catch (err) {
    toasts?.error(err);
  }

  state.loading = false;
}

watch(
  () => state.currentPage,
  async currentPage => {
    await loadMembers(currentPage);
  }
);

onMounted(async () => {
  await loadMembers();
});
</script>
